import styled from "styled-components"
import RevealText from "./RevealText"
import SectionTitle from "./SectionTitle"


const AboutDiv = styled.div`
  padding-bottom: 25vh;
  max-width: 1280px;
  margin: 0 auto;

  & .introText {
    font-size: clamp(1.8rem, 2vw, 2.9rem);
    font-weight: 300;
  }

`


export default function About() {

  return (
    <AboutDiv className="grid--3-2 grid--m-1 center">
      <div className="content-wrap">
        <SectionTitle >
          <h2><RevealText delay="0.3" windowPosition="90%">About Me</RevealText></h2>
        </SectionTitle>

        <RevealText className="introText" delay="0.45" windowPosition="90%">
          I am a web developer with nearly 10+ years experience. Based in Queenstown, I specialise in building solutions for the hospitality, tourism and accommodation sectors all around the world.
        </RevealText>
        <br />
        <br />
        <RevealText className="introText" delay="0.6" windowPosition="90%">
          Dad to two beautiful kids. Crypto & NFT enthusiast and advocate for the Fluf World Ecosystem.
        </RevealText>
      </div>
    
    </AboutDiv>
  )
}
