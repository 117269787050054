import styled from "styled-components"
import device from "../theme/MediaQueries"
import RevealText from "./RevealText"

const HeroDiv = styled.div`

  max-width: 1280px;
  margin: 0 auto;

  padding-top: 25vh;
  padding-bottom: 25vh;

  & h1 {
    font-size: clamp(6rem,6vw,11rem);
    line-height: 1;
    margin: 0;
    font-weight: 700;

    @media ${device.MXmd} {
      font-size: clamp(6rem,24vw,9rem);
    }
  }

  & .descripton {
    width: 100%;
    font-size: clamp(1.3rem, 1.42vw, 1.8rem);

    @media ${device.MXmd} {
      font-size: clamp(2rem,5.52vw,3rem);
    }
  }

`

const DescList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media ${device.MXmd} {
      flex-direction: column;
    }

    & li {
      margin-right: 8px;
    }

`

export default function Hero() {
  return (
    <HeroDiv>
      <h1><RevealText>Hi, I'm Rich...</RevealText></h1>
      <DescList className="descripton">
        <li><RevealText delay="0.5">Web Developer.</RevealText></li> <li><RevealText delay="0.75">UI/UX Designer.</RevealText></li> <li><RevealText delay="0.9">Crypto & NFT Enthusiast.</RevealText></li>
      </DescList>
    </HeroDiv>
  )
}
