import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import device from "../theme/MediaQueries"
import { faTwitter, faLinkedin /* faDiscord */ } from '@fortawesome/free-brands-svg-icons'
import PinkBear from "../images/pink-bear.png"
import RevealText from "./RevealText"

const FooterDiv = styled.div`

  max-width: 1280px;
  margin: 0 auto;

  @media ${device.MXmd} {
    padding-top: 15vh
  }

  & h1,
  & h2 {
    font-size: clamp(4rem, 4vw, 9rem);
    line-height: 1;
    margin: 0;
  }
  & .center {
    align-content: center;
    align-items: center;
  }

  .footer-image {
    text-align: right;

    
    @media ${device.MXmd} {
      text-align: center
    }

    & img {
      margin-bottom: -1.8rem;
      width: 100%;
    }
  }

  & .socials {
    & a {
      margin-right: 0.8rem;
    }
  }
`

export default function Footer() {
  return (
    <FooterDiv>
      <div className="grid--2 grid--m-1 center">

        <div className="descripton text--l">
          <h2><RevealText>Contact</RevealText></h2>
          <div className="email">
            <a href="mailto:holla@richdeane.mozmail.com">Email Me</a>
          </div> 
          <div className="socials">
            <a href="https://twitter.com/QTRichieRich" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://www.linkedin.com/in/rich-deane-541072113" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
          </div> 
        </div>

        <div className="footer-image">
          <img src={PinkBear} className="footer-bear" alt="Pink Bear" />
        </div>

      </div>
    </FooterDiv>
  )
}
