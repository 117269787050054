import { useEffect, useRef } from "react"

import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
import styled from "styled-components";
import ScrollTrigger from "gsap/ScrollTrigger";

import { useContext } from 'react'
import { SiteContext } from '../context/SiteContext'

import device from "../theme/MediaQueries"

gsap.registerPlugin(SplitText, ScrollTrigger);

const RevealTextDiv = styled.div`
  opacity: 0;

  & .split-parent {
    overflow: hidden;
  }
`

export default function RevealText({children, className, delay = 0, delayM = 0, delayS = 0, elementPosition = "top", windowPosition = "bottom"}) {

  const { fontLoaded } = useContext(SiteContext)

  const el = useRef()

  useEffect(() => {
    if (fontLoaded) {

      gsap.set(".revealText", {autoAlpha: 1});
      const childSplit = new SplitText(el.current, {
        type: "lines",
        linesClass: "split-child"
      });
      new SplitText(el.current, {
        type: "lines",
        linesClass: "split-parent"
      });
      
      let d = 0;

      if (window.innerWidth <= parseInt(device.xs.replace('px', ''))) {
        d = delayS;
      } else if (window.innerWidth <= parseInt(device.md.replace('px', ''))) {
        d = delayM;
      } else {
        d = delay;
      }

      gsap.utils.toArray(el.current).forEach(section => {
        gsap.from(childSplit.lines, {
          scrollTrigger: {
            trigger: section,
            start:`${elementPosition} ${windowPosition}`,
            // markers: true,
          },
          autoAlpha: 1,
          duration: 2,
          yPercent: 105,
          ease: "power4",
          stagger: 0.15,
          delay: d,
        });
      });
      
    }
      
  }, [delay, delayM, delayS, fontLoaded, elementPosition, windowPosition])

  return (
    <RevealTextDiv className={`revealText ${(className !== undefined) ? className : ''}`} ref={el}>{children}</RevealTextDiv>
  )
}
