import styled from "styled-components"
import Media from './Media';

const ImageWrapperDiv = styled.div`
  
`

export default function ImageWrapper({projects, activeIndex, x, y}) {
  
  // const xPos = isActive ? x : 0;
//  const yPos = isActive ? y : 0;
  const xPos = x;
  const yPos = y;
  
  const active = activeIndex !== 1;

  return (
    <ImageWrapperDiv projects={projects} className="">
      <Media active={active} url={projects[activeIndex].bannerImage} alt={projects[activeIndex].title} x={xPos} y={yPos} />
    </ImageWrapperDiv>
  )
}
