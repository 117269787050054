
import styled from "styled-components"
import Project from './Project';
import RevealText from "./RevealText";
import SectionTitle from "./SectionTitle";

const ProjectLink = styled.a`
  position: relative;
  z-index: 9999;
`
const WorkDiv = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`

export default function Work({projects, error, isPending, activeIndex, setActiveIndex}) {


  return (
    <WorkDiv className="owl--xs">
      <SectionTitle>
        <h2><RevealText>Recent Work</RevealText></h2>
      </SectionTitle>
      { error && <div>{error}</div>}
      { isPending && <div>Work Loading</div>}
      { projects.map((project, index)=> {
        return (
          <ProjectLink key={`img-${project.id}`} href={project.website} target="_blank" rel="noreferrer" >
            <Project key={project.id} activeIndex={activeIndex}  project={project} index={index} setActiveIndex={setActiveIndex} />
          </ProjectLink>
        )
      }) }
    </WorkDiv>
  )
}
