import { ThemeProvider } from 'styled-components';
import GlobalStyle from "./theme/GlobalStyle"
import ThemeDefault from "./theme/ThemeDefault";
// import { useState } from 'react';
// import device from './theme/MediaQueries';
import ReactGA from 'react-ga4';


import { collection, getDocs, query, orderBy } from "firebase/firestore"; 
import { db } from "./firebase/config"
import { useEffect, useState } from 'react';
import styled from 'styled-components'
import Canvas from './components/Canvas';
import About from './components/About';

import Hero from './components/Hero';

import Footer from './components/Footer';
import ImageWrapper from './components/ImageWrapper';
import Work from './components/Work';
import SkillSet from './components/Skillset';
import device from './theme/MediaQueries';


const AppDiv = styled.div`
  padding: 4.8rem;
  text-align: left;

  @media ${device.MXmd} {
    padding: 2.4rem;
  }
`

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({x: 0, y: 0})

  const updateMousePostion = event => {
    setMousePosition({x: event.clientX, y: event.clientY})
  }
  useEffect(()=>{
    window.addEventListener('mousemove', updateMousePostion);

    return () => window.removeEventListener('mousemove', updateMousePostion);
  }, [])

  return mousePosition
}

function App() {
  const [projects, setProjects] = useState(null);
  const [error, setError] = useState(false)
  const [isPending, setIsPending] = useState(false)

  const [activeIndex, setActiveIndex] = useState()
  const {x, y} = useMousePosition();

  ReactGA.initialize('G-359EWHPZ5G');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});

    var EA = document.createComment(`
----------------------------------------------------------------------------------------------------\n
-------------------------FOR----THE----SEEKERS----THAT----DIDN'T----MAKE----IT----------------------\n
----------------------------------------------------------------------------------------------------\n
-----------------------------------------------------------#BGBB#&----------------------------------\n
-----------------------------#&---------------&&&&&&&----&&#BP5G###---------------------------------\n
-----------------------------&&&&---------&&#GP5PPGB##B#B###&BB####&--------------------------------\n
--------------------------------&&&&----&&&&&#&&&&#BB##&&&&##&##&&----------------------------------\n
----------------------------------&&&BB#BG##&#BB&BB##&#&&&@&&&&&&-----------------------------------\n
------------------------------------&##B####BB&BB&BBGGGBGB#&&&&&&&&&--------------------------------\n
-------------------------------------&#&#GP555PGGGGPGP55G##&&&&@@&&---------------------------------\n
------------------------------------&##GPPP5PGGBB##BBGGP5G#&&&&&@@&---------------------------------\n
------------------------------------##GPP5YGBB##G?7JBBBB5PB#&&&&&&&&------&&&-----------------------\n
-----------------------------------##BGB? - !#&&&7 - - 5&#BGYP##&&-&&&&----&&-##--------------------\n
----------------------------------&###B#Y - 7&&&&G - - B&##BJ!?PBB&&&&&-&&&&--##--------------------\n
--------------------------------&GGB&#### - #&&&&@ - - &&##B#5YPYJP&&&&&---&&-##--------------------\n
------------------&#&-----------P?7J5GB#& - @@@@@& - - &&#GY55PGB&@@&&--------&&--------------------\n
----------#B##&---#PGB##---------&#BB&BBB#&&&&&&&###BG555P#&&&##&&&---------------------------------\n
----------BGB####&&-&&&#&-------###&&&&&###&BBGP55GPPGGB#&&&&&&&B##&--------------------------------\n
----------&B#&#BB#######&&&&-&&#GG#@@&&@@&###BBGGGBBG#&&@@&@&@@@&#&-&#&&&---------------------------\n
----------&B#&&-&&#####&&-&&&-&&----------#&&&&&#&&&#&@@@-------@&&####&&&&-------------------------\n
------------##&##&&&&&&-&&&---------------&@@&&&##&@&&&&@&-----&##B##&&&&&&&#&----------------------\n
--------------------------------------------&&&&@&&@@@&&-------&##&&&#&&&&#&&B##&-------------------\n
---------------------------------------------------------------&&#BPG&-------###&&------------------\n
---------------------------------------------------------------&&#BB&-------&&#B#&------------------\n
----------------------------------------------------------------&&---------&&&##&&&-----------------\n
------------------------------------------------------------------&&---&&&&B####&&------------------\n
-----------------------------------------------------------------------&&&#G####&-------------------\n
-------------------------------------------------------------------------&&##&#&--------------------\n
-------------------------------------------------------------------------#&&###---------------------\n
------------------------------------------------------------------------##BGB#&---------------------\n
------------------------------------------------------------------------&&&&------------------------\n
----------------------------------------------------------------------------------------------------\n
----------------------------------------------------------------------------------------------------\n
`);
document.head.appendChild(EA);


  }, []);

  useEffect(() => {

    setActiveIndex(-1)
    setIsPending(true)

    const ref = collection(db, 'projects');
    const projectsRef = query(ref, orderBy('order', 'asc'));
    
    getDocs(projectsRef).then(snapshot => {

    
      if (snapshot.empty) {
        setError('Oops... there was an issue, please try again later.')
        setIsPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })

        setProjects(results)
        setIsPending(false)

      }
      }).catch(err => {
      setError(err.message)
      setIsPending(false)
    })

  }, [])


  return (
    <ThemeProvider theme={ThemeDefault}>
      <GlobalStyle />
      <AppDiv className="App no-pad-bottom">
        <Canvas />
        <Hero />
        <About />
        <SkillSet />
        { (projects && activeIndex !== -1)  && <ImageWrapper projects={projects} activeIndex={activeIndex} x={x} y={y} /> }
        { projects && <Work isPending={isPending} isError={error} projects={projects} activeIndex={activeIndex} setActiveIndex={setActiveIndex} /> }
        <Footer />

      </AppDiv>
    </ThemeProvider>
  );
}

export default App;
