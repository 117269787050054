import { createContext, useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver"

export const SiteContext = createContext();

export function SiteProvider({ children }) {

  const [fontLoaded, setFontLoaded] = useState(false);
  
  useEffect(() => {
    const font = new FontFaceObserver('Mont');
    font.load().then(()=>{
      console.log('Font Loaded')
      setFontLoaded(true);
    });
  },[])

  return (
    <SiteContext.Provider value={{fontLoaded: fontLoaded}}>
      {children}
    </SiteContext.Provider>
  )
}