import { createGlobalStyle } from "styled-components"
// import "normalize.css/normalize.css"

import ThemeDefault from "./ThemeDefault";

import {device} from "./MediaQueries"
import Fonts from "./Fonts"

const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Mont';
		font-weight: 400;
		font-style: normal;
		src: url(${Fonts.MontWOFF2}) format('woff2'),
			url(${Fonts.MontWOFF}) format('woff');
		font-display: block;
	}

	@font-face {
		font-family: 'Mont';
		font-weight: 700;
		font-style: normal;
		src: url(${Fonts.MontBoldWOFF2}) format('woff2'),
			url(${Fonts.MontBoldWOFF}) format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Mont';
		font-weight: 300;
		font-style: normal;
		src: url(${Fonts.MontLightWOFF2}) format('woff2'),
			url(${Fonts.MontLightWOFF}) format('woff');
		font-display: swap;
	}

	* {
		box-sizing: border-box;
	}

	html {
		height: -webkit-fill-available;
		font-size: 62.5%;
	}

	body {
		margin: 0;
		padding: 0;
		background: ${ThemeDefault.black};
		font-family: ${ThemeDefault.fontPrimary};
		text-align: center;
		line-height: 1.5;
		width: 100%;
		color: ${ThemeDefault.white};
		min-height: 100vh;
		min-height: -webkit-fill-available;
		font-size: 1.6rem;
		font-size: clamp(1.5rem, calc(0.2083333333vw + 1.5333333333rem), 1.6rem);
	}
  
	a {
		color: ${ThemeDefault.white};
		text-decoration: none;
	}
	
	a:hover {
		${ThemeDefault.secondary}
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 400;
	}

	.page-width {
    max-width: 1280px;
    margin: 0 auto;
  }

	p {
		margin-block-start: 0;
	}

	p:last-of-type {
		margin-block-end: 0;
	}

	.App {
		min-height: 100vh;
	}
	.page-wrap {
		min-height: 90vh;
	}
	.padding-section {
		padding: 2.4rem;

		@media ${device.MXmd} {
			padding: 1.2rem;
		}
		
	}
	.no-pad-bottom {
		padding-bottom: 0 !important;
	}
	.padding-section section {
		padding-left: 0;
		padding-right: 0;
	}
	/* Version: 1.2.0 - Generated 2022-03-19 09:28:41 */
	.aspect--1-1 {
		aspect-ratio: 1 / 1;
	}
	img.aspect--1-1 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--1-1 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 1 / 1;
	}
	.aspect--1-2 {
		aspect-ratio: 1 / 2;
	}
	img.aspect--1-2 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--1-2 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 1 / 2;
	}
	.aspect--2-1 {
		aspect-ratio: 2 / 1;
	}
	img.aspect--2-1 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--2-1 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 2 / 1;
	}
	.aspect--2-3 {
		aspect-ratio: 2 / 3;
	}
	img.aspect--2-3 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--2-3 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 2 / 3;
	}
	.aspect--3-2 {
		aspect-ratio: 3 / 2;
	}
	img.aspect--3-2 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--3-2 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 3 / 2;
	}
	.aspect--3-4 {
		aspect-ratio: 3 / 4;
	}
	img.aspect--3-4 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--3-4 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 3 / 4;
	}
	.aspect--4-3 {
		aspect-ratio: 4 / 3;
	}
	img.aspect--4-3 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--4-3 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 4 / 3;
	}
	.aspect--16-9 {
		aspect-ratio: 16 / 9;
	}
	img.aspect--16-9 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--16-9 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 16 / 9;
	}
	.aspect--9-16 {
		aspect-ratio: 9 / 16;
	}
	img.aspect--9-16 {
		-o-object-fit: cover;
		object-fit: cover;
	}
	.aspect--9-16 iframe {
		width: 100%;
		height: 100%;
		aspect-ratio: 9 / 16;
	}
	.width--xs:not([class*="breakout--"]) {
		width: 100%;
		max-width: calc((128 * 0.1) * 1rem);
	}
	.width--s:not([class*="breakout--"]) {
		width: 100%;
		max-width: calc((128 * 0.2) * 1rem);
	}
	.width--m:not([class*="breakout--"]) {
		width: 100%;
		max-width: calc((128 * 0.4) * 1rem);
	}
	.width--l:not([class*="breakout--"]) {
		width: 100%;
		max-width: calc((128 * 0.6) * 1rem);
	}
	.width--xl:not([class*="breakout--"]) {
		width: 100%;
		max-width: calc((128 * 0.8) * 1rem);
	}
	.width--xxl:not([class*="breakout--"]) {
		width: 100%;
		max-width: calc((128 * 0.9) * 1rem);
	}
	.width--vp-max:not([class*="breakout--"]) {
		width: 100%;
		max-width: calc((128 - 3 * 2) * 1rem);
		margin-left: auto;
		margin-right: auto;
	}
	.width--50:not([class*="breakout--"]) {
		width: 100%;
		max-width: calc((128 / 2) * 1rem);
	}
	.width--full:not([class*="breakout--"]) {
		width: 100%;
		max-width: 100%;
	}
	.width--auto {
		width: auto !important;
		width: 100%;
	}
	.rounded--xs {
		border-radius: 0.4444444444rem;
	}
	.rounded--s {
		border-radius: 0.6666666667rem;
	}
	.rounded--m {
		border-radius: 1rem;
	}
	.rounded--l {
		border-radius: 1.5rem;
	}
	.rounded--xl {
		border-radius: 2.25rem;
	}
	.rounded--xxl {
		border-radius: 3.375rem;
	}
	.rounded--50 {
		border-radius: 50%;
	}
	.rounded--circle {
		border-radius: 50%;
	}

	@media ${device.MXlg} {
		.aspect--xl-1-1 {
			aspect-ratio: 1 / 1;
		}
		img.aspect--xl-1-1 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-1-1 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 1;
		}
		.aspect--xl-1-2 {
			aspect-ratio: 1 / 2;
		}
		img.aspect--xl-1-2 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-1-2 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 2;
		}
		.aspect--xl-2-1 {
			aspect-ratio: 2 / 1;
		}
		img.aspect--xl-2-1 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-2-1 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 1;
		}
		.aspect--xl-2-3 {
			aspect-ratio: 2 / 3;
		}
		img.aspect--xl-2-3 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-2-3 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 3;
		}
		.aspect--xl-3-2 {
			aspect-ratio: 3 / 2;
		}
		img.aspect--xl-3-2 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-3-2 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 2;
		}
		.aspect--xl-3-4 {
			aspect-ratio: 3 / 4;
		}
		img.aspect--xl-3-4 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-3-4 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 4;
		}
		.aspect--xl-4-3 {
			aspect-ratio: 4 / 3;
		}
		img.aspect--xl-4-3 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-4-3 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 4 / 3;
		}
		.aspect--xl-16-9 {
			aspect-ratio: 16 / 9;
		}
		img.aspect--xl-16-9 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-16-9 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 16 / 9;
		}
		.aspect--xl-9-16 {
			aspect-ratio: 9 / 16;
		}
		img.aspect--xl-9-16 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--xl-9-16 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 9 / 16;
		}
	}
	@media ${device.MXmd} {
		.aspect--l-1-1 {
			aspect-ratio: 1 / 1;
		}
		img.aspect--l-1-1 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-1-1 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 1;
		}
		.aspect--l-1-2 {
			aspect-ratio: 1 / 2;
		}
		img.aspect--l-1-2 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-1-2 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 2;
		}
		.aspect--l-2-1 {
			aspect-ratio: 2 / 1;
		}
		img.aspect--l-2-1 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-2-1 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 1;
		}
		.aspect--l-2-3 {
			aspect-ratio: 2 / 3;
		}
		img.aspect--l-2-3 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-2-3 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 3;
		}
		.aspect--l-3-2 {
			aspect-ratio: 3 / 2;
		}
		img.aspect--l-3-2 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-3-2 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 2;
		}
		.aspect--l-3-4 {
			aspect-ratio: 3 / 4;
		}
		img.aspect--l-3-4 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-3-4 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 4;
		}
		.aspect--l-4-3 {
			aspect-ratio: 4 / 3;
		}
		img.aspect--l-4-3 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-4-3 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 4 / 3;
		}
		.aspect--l-16-9 {
			aspect-ratio: 16 / 9;
		}
		img.aspect--l-16-9 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-16-9 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 16 / 9;
		}
		.aspect--l-9-16 {
			aspect-ratio: 9 / 16;
		}
		img.aspect--l-9-16 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--l-9-16 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 9 / 16;
		}
	}
	@media ${device.MXsm} {
		.aspect--m-1-1 {
			aspect-ratio: 1 / 1;
		}
		img.aspect--m-1-1 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-1-1 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 1;
		}
		.aspect--m-1-2 {
			aspect-ratio: 1 / 2;
		}
		img.aspect--m-1-2 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-1-2 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 2;
		}
		.aspect--m-2-1 {
			aspect-ratio: 2 / 1;
		}
		img.aspect--m-2-1 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-2-1 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 1;
		}
		.aspect--m-2-3 {
			aspect-ratio: 2 / 3;
		}
		img.aspect--m-2-3 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-2-3 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 3;
		}
		.aspect--m-3-2 {
			aspect-ratio: 3 / 2;
		}
		img.aspect--m-3-2 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-3-2 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 2;
		}
		.aspect--m-3-4 {
			aspect-ratio: 3 / 4;
		}
		img.aspect--m-3-4 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-3-4 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 4;
		}
		.aspect--m-4-3 {
			aspect-ratio: 4 / 3;
		}
		img.aspect--m-4-3 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-4-3 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 4 / 3;
		}
		.aspect--m-16-9 {
			aspect-ratio: 16 / 9;
		}
		img.aspect--m-16-9 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-16-9 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 16 / 9;
		}
		.aspect--m-9-16 {
			aspect-ratio: 9 / 16;
		}
		img.aspect--m-9-16 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--m-9-16 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 9 / 16;
		}
	}
	@media ${device.MXxs} {
		.aspect--s-1-1 {
			aspect-ratio: 1 / 1;
		}
		img.aspect--s-1-1 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-1-1 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 1;
		}
		.aspect--s-1-2 {
			aspect-ratio: 1 / 2;
		}
		img.aspect--s-1-2 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-1-2 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / 2;
		}
		.aspect--s-2-1 {
			aspect-ratio: 2 / 1;
		}
		img.aspect--s-2-1 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-2-1 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 1;
		}
		.aspect--s-2-3 {
			aspect-ratio: 2 / 3;
		}
		img.aspect--s-2-3 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-2-3 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 3;
		}
		.aspect--s-3-2 {
			aspect-ratio: 3 / 2;
		}
		img.aspect--s-3-2 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-3-2 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 2;
		}
		.aspect--s-3-4 {
			aspect-ratio: 3 / 4;
		}
		img.aspect--s-3-4 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-3-4 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 4;
		}
		.aspect--s-4-3 {
			aspect-ratio: 4 / 3;
		}
		img.aspect--s-4-3 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-4-3 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 4 / 3;
		}
		.aspect--s-16-9 {
			aspect-ratio: 16 / 9;
		}
		img.aspect--s-16-9 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-16-9 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 16 / 9;
		}
		.aspect--s-9-16 {
			aspect-ratio: 9 / 16;
		}
		img.aspect--s-9-16 {
			-o-object-fit: cover;
			object-fit: cover;
		}
		.aspect--s-9-16 iframe {
			width: 100%;
			height: 100%;
			aspect-ratio: 9 / 16;
		}
	}
	@supports not (aspect-ratio: 1.7777777778) {
		.aspect--1-1::before {
			float: left;
			padding-top: 100%;
			content: "";
		}
		.aspect--1-1::after {
			display: block;
			content: "";
			clear: both;
		}
		.aspect--1-2::before {
			float: left;
			padding-top: 50%;
			content: "";
		}
		.aspect--1-2::after {
			display: block;
			content: "";
			clear: both;
		}
		.aspect--2-1::before {
			float: left;
			padding-top: 200%;
			content: "";
		}
		.aspect--2-1::after {
			display: block;
			content: "";
			clear: both;
		}
		.aspect--2-3::before {
			float: left;
			padding-top: 150%;
			content: "";
		}
		.aspect--2-3::after {
			display: block;
			content: "";
			clear: both;
		}
		.aspect--3-2::before {
			float: left;
			padding-top: 66%;
			content: "";
		}
		.aspect--3-2::after {
			display: block;
			content: "";
			clear: both;
		}
		.aspect--3-4::before {
			float: left;
			padding-top: 133%;
			content: "";
		}
		.aspect--3-4::after {
			display: block;
			content: "";
			clear: both;
		}
		.aspect--4-3::before {
			float: left;
			padding-top: 75%;
			content: "";
		}
		.aspect--4-3::after {
			display: block;
			content: "";
			clear: both;
		}
		.aspect--16-9::before {
			float: left;
			padding-top: 56.25%;
			content: "";
		}
		.aspect--16-9::after {
			display: block;
			content: "";
			clear: both;
		}
		.aspect--9-16::before {
			float: left;
			padding-top: 177%;
			content: "";
		}
		.aspect--9-16::after {
			display: block;
			content: "";
			clear: both;
		}
		@media ${device.MXlg} {
			.aspect--1-1::before {
				float: left;
				padding-top: 100%;
				content: "";
			}
			.aspect--1-1::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--1-2::before {
				float: left;
				padding-top: 50%;
				content: "";
			}
			.aspect--1-2::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--2-1::before {
				float: left;
				padding-top: 200%;
				content: "";
			}
			.aspect--2-1::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--2-3::before {
				float: left;
				padding-top: 150%;
				content: "";
			}
			.aspect--2-3::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--3-2::before {
				float: left;
				padding-top: 66%;
				content: "";
			}
			.aspect--3-2::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--3-4::before {
				float: left;
				padding-top: 133%;
				content: "";
			}
			.aspect--3-4::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--4-3::before {
				float: left;
				padding-top: 75%;
				content: "";
			}
			.aspect--4-3::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--16-9::before {
				float: left;
				padding-top: 56.25%;
				content: "";
			}
			.aspect--16-9::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--9-16::before {
				float: left;
				padding-top: 177%;
				content: "";
			}
			.aspect--9-16::after {
				display: block;
				content: "";
				clear: both;
			}
		}
		@media ${device.MXmd} {
			.aspect--1-1::before {
				float: left;
				padding-top: 100%;
				content: "";
			}
			.aspect--1-1::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--1-2::before {
				float: left;
				padding-top: 50%;
				content: "";
			}
			.aspect--1-2::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--2-1::before {
				float: left;
				padding-top: 200%;
				content: "";
			}
			.aspect--2-1::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--2-3::before {
				float: left;
				padding-top: 150%;
				content: "";
			}
			.aspect--2-3::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--3-2::before {
				float: left;
				padding-top: 66%;
				content: "";
			}
			.aspect--3-2::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--3-4::before {
				float: left;
				padding-top: 133%;
				content: "";
			}
			.aspect--3-4::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--4-3::before {
				float: left;
				padding-top: 75%;
				content: "";
			}
			.aspect--4-3::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--16-9::before {
				float: left;
				padding-top: 56.25%;
				content: "";
			}
			.aspect--16-9::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--9-16::before {
				float: left;
				padding-top: 177%;
				content: "";
			}
			.aspect--9-16::after {
				display: block;
				content: "";
				clear: both;
			}
		}
		@media ${device.MXsm} {
			.aspect--1-1::before {
				float: left;
				padding-top: 100%;
				content: "";
			}
			.aspect--1-1::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--1-2::before {
				float: left;
				padding-top: 50%;
				content: "";
			}
			.aspect--1-2::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--2-1::before {
				float: left;
				padding-top: 200%;
				content: "";
			}
			.aspect--2-1::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--2-3::before {
				float: left;
				padding-top: 150%;
				content: "";
			}
			.aspect--2-3::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--3-2::before {
				float: left;
				padding-top: 66%;
				content: "";
			}
			.aspect--3-2::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--3-4::before {
				float: left;
				padding-top: 133%;
				content: "";
			}
			.aspect--3-4::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--4-3::before {
				float: left;
				padding-top: 75%;
				content: "";
			}
			.aspect--4-3::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--16-9::before {
				float: left;
				padding-top: 56.25%;
				content: "";
			}
			.aspect--16-9::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--9-16::before {
				float: left;
				padding-top: 177%;
				content: "";
			}
			.aspect--9-16::after {
				display: block;
				content: "";
				clear: both;
			}
		}
		@media ${device.MXxs} {
			.aspect--1-1::before {
				float: left;
				padding-top: 100%;
				content: "";
			}
			.aspect--1-1::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--1-2::before {
				float: left;
				padding-top: 50%;
				content: "";
			}
			.aspect--1-2::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--2-1::before {
				float: left;
				padding-top: 200%;
				content: "";
			}
			.aspect--2-1::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--2-3::before {
				float: left;
				padding-top: 150%;
				content: "";
			}
			.aspect--2-3::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--3-2::before {
				float: left;
				padding-top: 66%;
				content: "";
			}
			.aspect--3-2::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--3-4::before {
				float: left;
				padding-top: 133%;
				content: "";
			}
			.aspect--3-4::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--4-3::before {
				float: left;
				padding-top: 75%;
				content: "";
			}
			.aspect--4-3::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--16-9::before {
				float: left;
				padding-top: 56.25%;
				content: "";
			}
			.aspect--16-9::after {
				display: block;
				content: "";
				clear: both;
			}
			.aspect--9-16::before {
				float: left;
				padding-top: 177%;
				content: "";
			}
			.aspect--9-16::after {
				display: block;
				content: "";
				clear: both;
			}
		}
	}
	
	section > .section-inner {
		padding: 6rem 2.4rem;
		padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 96))) 2.4rem;
		padding: clamp(7.2rem, calc(5vw + 5.6rem), 12rem) 2.4rem;
	}

	.pad-section--xs {
		padding: 1.896rem 2.4rem;
		padding: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 96))) 2.4rem;
		padding: clamp(3.2rem, calc(2.2222222222vw + 2.4888888889rem), 5.3333333333rem) 2.4rem;
	}
	.pad-section--s {
		padding: 2.133rem 2.4rem;
		padding: calc(4.8rem + (3.2 * ((100vw - 32rem) / 96))) 2.4rem;
		padding: clamp(4.8rem, calc(3.3333333333vw + 3.7333333333rem), 8rem) 2.4rem;
	}
	.pad-section--m {
		padding: 2.4rem 2.4rem;
		padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 96))) 2.4rem;
		padding: clamp(7.2rem, calc(5vw + 5.6rem), 12rem) 2.4rem;
	}
	.pad-section--l {
		padding: 2.7rem 2.4rem;
		padding: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 96))) 2.4rem;
		padding: clamp(9.5976rem, calc(8.7525vw + 6.7968rem), 18rem) 2.4rem;
	}
	.pad-section--xl {
		padding: 3.038rem 2.4rem;
		padding: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 96))) 2.4rem;
		padding: clamp(12.7936008rem, calc(14.7983325vw + 8.0581344rem), 27rem) 2.4rem;
	}
	.pad-section--xxl {
		padding: 3.42rem 2.4rem;
		padding: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 96))) 2.4rem;
		padding: clamp(17.0538698664rem, calc(24.4230522225vw + 9.2384931552rem), 40.5rem) 2.4rem;
	}
	.pad-section--none {
		padding: 0 2.4rem;
	}
	.pad--none {
		padding: 0 !important;
	}
	.height--20 {
		min-height: 20vh;
	}
	.height--30 {
		min-height: 30vh;
	}
	.height--40 {
		min-height: 40vh;
	}
	.height--50 {
		min-height: 50vh;
	}
	.height--60 {
		min-height: 60vh;
	}
	.height--70 {
		min-height: 70vh;
	}
	.height--80 {
		min-height: 80vh;
	}
	.height--90 {
		min-height: 90vh;
	}
	.height--full {
		min-height: 100vh;
	}
	.max-height--20 {
		max-height: 20vh;
	}
	.max-height--30 {
		max-height: 30vh;
	}
	.max-height--40 {
		max-height: 40vh;
	}
	.max-height--50 {
		max-height: 50vh;
	}
	.max-height--60 {
		max-height: 60vh;
	}
	.max-height--70 {
		max-height: 70vh;
	}
	.max-height--80 {
		max-height: 80vh;
	}
	.max-height--90 {
		max-height: 90vh;
	}
	.max-height--full {
		max-height: 100vh;
	}
	.owl--xs > * + * {
		margin-top: 1.896rem;
		margin-top: calc(1.0666666667rem + (0.2666666667 * ((100vw - 32rem) / 96)));
		margin-top: clamp(1.0666666667rem, calc(0.2777777778vw + 0.9777777778rem), 1.3333333333rem);
	}
	.owl--s > * + * {
		margin-top: 2.133rem;
		margin-top: calc(1.6rem + (0.4 * ((100vw - 32rem) / 96)));
		margin-top: clamp(1.6rem, calc(0.4166666667vw + 1.4666666667rem), 2rem);
	}
	.owl--m > * + * {
		margin-top: 2.4rem;
		margin-top: calc(2.4rem + (0.6 * ((100vw - 32rem) / 96)));
		margin-top: clamp(2.4rem, calc(0.625vw + 2.2rem), 2.4rem);
	}
	.owl--l > * + * {
		margin-top: 2.7rem;
		margin-top: calc(3.1992rem + (1.3008 * ((100vw - 32rem) / 96)));
		margin-top: clamp(3.1992rem, calc(1.355vw + 2.7656rem), 4.5rem);
	}
	.owl--xl > * + * {
		margin-top: 3.038rem;
		margin-top: calc(4.2645336rem + (2.4854664 * ((100vw - 32rem) / 96)));
		margin-top: clamp(4.2645336rem, calc(2.5890275vw + 3.4360448rem), 6.75rem);
	}
	.owl--xxl > * + * {
		margin-top: 3.42rem;
		margin-top: calc(5.6846232888rem + (4.4403767112 * ((100vw - 32rem) / 96)));
		margin-top: clamp(5.6846232888rem, calc(4.6253924075vw + 4.2044977184rem), 10.125rem);
	}
	.flex--col {
		display: flex;
		flex-direction: column;
	}
	.flex--row {
		display: flex;
		flex-direction: row;
	}
	.flex--col-reverse {
		display: flex;
		flex-direction: column-reverse;
	}
	.flex--row-reverse {
		display: flex;
		flex-direction: row-reverse;
	}
	@media ${device.MXlg} {
		.flex--col-xl {
			display: flex;
			flex-direction: column;
		}
		.flex--row-xl {
			display: flex;
			flex-direction: row;
		}
		.flex--col-reverse-xl {
			display: flex;
			flex-direction: column-reverse;
		}
		.flex--row-reverse-xl {
			display: flex;
			flex-direction: row-reverse;
		}
	}
	@media ${device.MXmd} {
		.flex--col-l {
			display: flex;
			flex-direction: column;
		}
		.flex--row-l {
			display: flex;
			flex-direction: row;
		}
		.flex--col-reverse-l {
			display: flex;
			flex-direction: column-reverse;
		}
		.flex--row-reverse-l {
			display: flex;
			flex-direction: row-reverse;
		}
	}
	@media ${device.MXsm} {
		.flex--col-m {
			display: flex;
			flex-direction: column;
		}
		.flex--row-m {
			display: flex;
			flex-direction: row;
		}
		.flex--col-reverse-m {
			display: flex;
			flex-direction: column-reverse;
		}
		.flex--row-reverse-m {
			display: flex;
			flex-direction: row-reverse;
		}
	}
	@media ${device.MXxs} {
		.flex--col-s {
			display: flex;
			flex-direction: column;
		}
		.flex--row-s {
			display: flex;
			flex-direction: row;
		}
		.flex--col-reverse-s {
			display: flex;
			flex-direction: column-reverse;
		}
		.flex--row-reverse-s {
			display: flex;
			flex-direction: row-reverse;
		}
	}
	.justify-content--start {
		display: flex;
		justify-content: flex-start;
	}
	.justify-content--end {
		display: flex;
		justify-content: flex-end;
	}
	.justify-content--center {
		display: flex;
		justify-content: center;
	}
	.justify-content--between {
		display: flex;
		justify-content: space-between;
	}
	.justify-content--around {
		display: flex;
		justify-content: space-around;
	}
	.justify-content--stretch {
		display: flex;
		justify-content: stretch;
	}
	.align-content--start {
		display: flex;
		align-content: flex-start;
	}
	.align-content--end {
		display: flex;
		align-content: flex-end;
	}
	.align-content--center {
		display: flex;
		align-content: center;
	}
	.align-content--baseline {
		display: flex;
		align-content: baseline;
	}
	.align-content--stretch {
		display: flex;
		align-content: stretch;
	}
	.justify-items--start {
		display: flex;
		justify-items: flex-start;
	}
	.justify-items--center {
		display: flex;
		justify-items: center;
	}
	.justify-items--end {
		display: flex;
		justify-items: flex-end;
	}
	.justify-items--stretch {
		display: flex;
		justify-items: stretch;
	}
	.align-items--start {
		display: flex;
		align-items: flex-start;
	}
	.align-items--end {
		display: flex;
		align-items: flex-end;
	}
	.align-items--center {
		display: flex;
		align-items: center;
	}
	.align-items--baseline {
		display: flex;
		align-items: baseline;
	}
	.align-items--stretch {
		display: flex;
		align-items: stretch;
	}
	.self--start {
		display: flex;
		align-self: flex-start;
	}
	.self--end {
		display: flex;
		align-self: flex-end;
	}
	.self--stretch {
		display: flex;
		align-self: stretch;
	}
	.grid--1 {
		display: grid !important;
		grid-template-columns: 1fr;
		width: 100%;
	}
	.grid--2 {
		display: grid !important;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		width: 100%;
	}
	.grid--3 {
		display: grid !important;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		width: 100%;
	}
	.grid--4 {
		display: grid !important;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		width: 100%;
	}
	.grid--5 {
		display: grid !important;
		grid-template-columns: repeat(5, minmax(0, 1fr));
		width: 100%;
	}
	.grid--6 {
		display: grid !important;
		grid-template-columns: repeat(6, minmax(0, 1fr));
		width: 100%;
	}
	.grid--1-2 {
		display: grid !important;
		grid-template-columns: 1fr 2fr;
		width: 100%;
	}
	.grid--1-3 {
		display: grid !important;
		grid-template-columns: 1fr 3fr;
		width: 100%;
	}
	.grid--2-1 {
		display: grid !important;
		grid-template-columns: 2fr 1fr;
		width: 100%;
	}
	.grid--2-3 {
		display: grid !important;
		grid-template-columns: 2fr 3fr;
		width: 100%;
	}
	.grid--3-1 {
		display: grid !important;
		grid-template-columns: 3fr 1fr;
		width: 100%;
	}
	.grid--3-2 {
		display: grid !important;
		grid-template-columns: 3fr 2fr;
		width: 100%;
	}
	.grid--auto-2 {
		display: grid !important;
		grid-template-columns: repeat(auto-fit, minmax(min(40.8026755853rem, 100vw - 6rem), 1fr));
		width: 100%;
	}
	.grid--auto-3 {
		display: grid !important;
		grid-template-columns: repeat(auto-fit, minmax(min(30.5764411028rem, 100vw - 6rem), 1fr));
		width: 100%;
	}
	.grid--auto-4 {
		display: grid !important;
		grid-template-columns: repeat(auto-fit, minmax(min(24.4488977956rem, 100vw - 6rem), 1fr));
		width: 100%;
	}
	.grid--auto-5 {
		display: grid !important;
		grid-template-columns: repeat(auto-fit, minmax(min(20.367278798rem, 100vw - 6rem), 1fr));
		width: 100%;
	}
	.grid--auto-6 {
		display: grid !important;
		grid-template-columns: repeat(auto-fit, minmax(min(17.4535050072rem, 100vw - 6rem), 1fr));
		width: 100%;
	}
	.grid--auto-1-2 {
		display: grid !important;
		grid-template-columns: 1fr 2fr;
		width: 100%;
	}
	.grid--auto-1-3 {
		display: grid !important;
		grid-template-columns: 1fr 3fr;
		width: 100%;
	}
	.grid--auto-2-1 {
		display: grid !important;
		grid-template-columns: 2fr 1fr;
		width: 100%;
	}
	.grid--auto-2-3 {
		display: grid !important;
		grid-template-columns: 2fr 3fr;
		width: 100%;
	}
	.grid--auto-3-1 {
		display: grid !important;
		grid-template-columns: 3fr 1fr;
		width: 100%;
	}
	.grid--auto-3-2 {
		display: grid !important;
		grid-template-columns: 3fr 2fr;
		width: 100%;
	}
	@media ${device.MXlg} {
		.grid--xl-1 {
			grid-template-columns: 1fr;
		}
		.grid--xl-2 {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		.grid--xl-3 {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		.grid--xl-4 {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
		.grid--xl-5 {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}
		.grid--xl-6 {
			grid-template-columns: repeat(6, minmax(0, 1fr));
		}
	}
	@media ${device.MXmd} {
		.grid--l-1 {
			grid-template-columns: 1fr;
		}
		.grid--l-2 {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		.grid--l-3 {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		.grid--l-4 {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
		.grid--l-5 {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}
		.grid--l-6 {
			grid-template-columns: repeat(6, minmax(0, 1fr));
		}
	}
	@media ${device.MXsm} {
		.grid--m-1 {
			grid-template-columns: 1fr;
		}
		.grid--m-2 {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		.grid--m-3 {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		.grid--m-4 {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
		.grid--m-5 {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}
		.grid--m-6 {
			grid-template-columns: repeat(6, minmax(0, 1fr));
		}
	}
	@media ${device.MXxs} {
		.grid--s-1 {
			grid-template-columns: 1fr;
		}
		.grid--s-2 {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		.grid--s-3 {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		.grid--s-4 {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
		.grid--s-5 {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}
		.grid--s-6 {
			grid-template-columns: repeat(6, minmax(0, 1fr));
		}
	}
	@media ${device.MXmd} {
		.grid--auto-1-2 {
			display: grid !important;
			grid-template-columns: repeat(auto-fit, minmax(min(30.5764411028rem, 100vw - 6rem), 1fr));
		}
		.grid--auto-1-3 {
			display: grid !important;
			grid-template-columns: repeat(auto-fit, minmax(min(30.5764411028rem, 100vw - 6rem), 1fr));
		}
		.grid--auto-3-2 {
			display: grid !important;
			grid-template-columns: repeat(auto-fit, minmax(min(30.5764411028rem, 100vw - 6rem), 1fr));
		}
		.grid--auto-2-3 {
			display: grid !important;
			grid-template-columns: repeat(auto-fit, minmax(min(30.5764411028rem, 100vw - 6rem), 1fr));
		}
		.grid--auto-2-1 {
			display: grid !important;
			grid-template-columns: repeat(auto-fit, minmax(min(30.5764411028rem, 100vw - 6rem), 1fr));
		}
		.grid--auto-3-1 {
			display: grid !important;
			grid-template-columns: repeat(auto-fit, minmax(min(30.5764411028rem, 100vw - 6rem), 1fr));
		}
	}
	.gap--xs {
		gap: 1.896rem;
		gap: calc(1.0666666667rem + (0.2666666667 * ((100vw - 32rem) / 96)));
		gap: clamp(1.0666666667rem, calc(0.2777777778vw + 0.9777777778rem), 1.3333333333rem);
	}
	.gap--s {
		gap: 2.133rem;
		gap: calc(1.6rem + (0.4 * ((100vw - 32rem) / 96)));
		gap: clamp(1.6rem, calc(0.4166666667vw + 1.4666666667rem), 2rem);
	}
	.gap--m {
		gap: 2.4rem;
		gap: calc(2.4rem + (0.6 * ((100vw - 32rem) / 96)));
		gap: clamp(2.4rem, calc(0.625vw + 2.2rem), 2.4rem);
	}
	.gap--l {
		gap: 2.7rem;
		gap: calc(3.1992rem + (1.3008 * ((100vw - 32rem) / 96)));
		gap: clamp(3.1992rem, calc(1.355vw + 2.7656rem), 4.5rem);
	}
	.gap--xl {
		gap: 3.038rem;
		gap: calc(4.2645336rem + (2.4854664 * ((100vw - 32rem) / 96)));
		gap: clamp(4.2645336rem, calc(2.5890275vw + 3.4360448rem), 6.75rem);
	}
	.gap--xxl {
		gap: 3.42rem;
		gap: calc(5.6846232888rem + (4.4403767112 * ((100vw - 32rem) / 96)));
		gap: clamp(5.6846232888rem, calc(4.6253924075vw + 4.2044977184rem), 10.125rem);
	}
	.btn--primary {
		background-color: var(--primary);
		color: var(--primary-ultra-light);
		cursor: pointer;
		border-style: solid;
		border-color: var(--primary);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		font-size: 1.8rem;
		font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
	}
	.btn--primary:hover {
		background-color: var(--primary-hover);
		color: var(--primary-ultra-light);
		border-color: var(--primary-hover);
		cursor: pointer;
	}
	.btn--primary.btn--outline {
		background-color: transparent;
		border-style: solid;
		cursor: pointer;
		color: var(--primary);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
	}
	.btn--primary.btn--outline:hover {
		background-color: var(--primary-hover);
		color: var(--primary-ultra-light);
	}
	.btn--secondary {
		background-color: var(--secondary);
		color: var(--secondary-ultra-light);
		cursor: pointer;
		border-style: solid;
		border-color: var(--secondary);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		font-size: 1.8rem;
		font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
	}
	.btn--secondary:hover {
		background-color: var(--secondary-hover);
		color: var(--secondary-ultra-light);
		border-color: var(--secondary-hover);
		cursor: pointer;
	}
	.btn--secondary.btn--outline {
		background-color: transparent;
		border-style: solid;
		cursor: pointer;
		color: var(--secondary);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
	}
	.btn--secondary.btn--outline:hover {
		background-color: var(--secondary-hover);
		color: var(--secondary-ultra-light);
	}
	.btn--accent {
		background-color: var(--accent);
		color: var(--accent-ultra-light);
		cursor: pointer;
		border-style: solid;
		border-color: var(--accent);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		font-size: 1.8rem;
		font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
	}
	.btn--accent:hover {
		background-color: var(--accent-hover);
		color: var(--accent-ultra-light);
		border-color: var(--accent-hover);
		cursor: pointer;
	}
	.btn--accent.btn--outline {
		background-color: transparent;
		border-style: solid;
		cursor: pointer;
		color: var(--accent);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
	}
	.btn--accent.btn--outline:hover {
		background-color: var(--accent-hover);
		color: var(--accent-ultra-light);
	}
	.btn--base {
		background-color: var(--base);
		color: var(--base-ultra-light);
		cursor: pointer;
		border-style: solid;
		border-color: var(--base);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		font-size: 1.8rem;
		font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
	}
	.btn--base:hover {
		background-color: var(--base-hover);
		color: var(--base-ultra-light);
		border-color: var(--base-hover);
		cursor: pointer;
	}
	.btn--base.btn--outline {
		background-color: transparent;
		border-style: solid;
		cursor: pointer;
		color: var(--base);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
	}
	.btn--base.btn--outline:hover {
		background-color: var(--base-hover);
		color: var(--base-ultra-light);
	}
	.btn--black {
		background-color: var(--shade-black);
		color: var(--shade-white);
		cursor: pointer;
		border-style: solid;
		border-color: var(--shade-black);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		font-size: 1.8rem;
		font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
	}
	.btn--black:hover {
		background-color: var(--primary-hover);
		color: var(--shade-white);
		border-color: var(--shade-white);
		cursor: pointer;
	}
	.btn--black.btn--outline {
		background-color: transparent;
		border-style: solid;
		cursor: pointer;
		color: var(--shade-black);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
	}
	.btn--black.btn--outline:hover {
		background-color: var(--primary-hover);
		color: var(--primary-ultra-light);
	}
	.btn--white {
		background-color: var(--shade-white);
		color: var(--shade-black);
		cursor: pointer;
		border-style: solid;
		border-color: var(--shade-white);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		font-size: 1.8rem;
		font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
	}
	.btn--white:hover {
		background-color: var(--primary-hover);
		color: var(--shade-white);
		border-color: var(--shade-black);
		cursor: pointer;
	}
	.btn--white.btn--outline {
		background-color: transparent;
		border-style: solid;
		cursor: pointer;
		color: var(--shade-white);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
	}
	.btn--white.btn--outline:hover {
		background-color: var(--primary-hover);
		color: var(--primary-ultra-light);
	}
	.btn--xs {
		font-size: 1.26rem;
		font-size: calc(0.9004501688rem + (0.1125562711 * ((100vw - 32rem) / 96)));
		font-size: max(1 * 1rem, 1.0130064399 * 1rem);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		cursor: pointer;
	}
	.btn--s {
		font-size: 1.42rem;
		font-size: calc(1.200300075rem + (0.1500375094 * ((100vw - 32rem) / 96)));
		font-size: max(1.1 * 1rem, 1.3503375844 * 1rem);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		cursor: pointer;
	}
	.btn--m {
		font-size: 1.8rem;
		font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		cursor: pointer;
	}
	.btn--l {
		font-size: 2.025rem;
		font-size: calc(1.92rem + (0.4794 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.92rem, calc(0.499375vw + 1.7602rem), 2.3994rem);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		cursor: pointer;
	}
	.btn--xl {
		font-size: 2.28rem;
		font-size: calc(2.304rem + (0.8944002 * ((100vw - 32rem) / 96)));
		font-size: clamp(2.304rem, calc(0.931666875vw + 2.0058666rem), 3.1984002rem);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		cursor: pointer;
	}
	.btn--xxl {
		font-size: 2.565rem;
		font-size: calc(2.7648rem + (1.4986674666 * ((100vw - 32rem) / 96)));
		font-size: clamp(2.7648rem, calc(1.5611119444vw + 2.2652441778rem), 4.2634674666rem);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		cursor: pointer;
	}
	@media ${device.MXlg} {
		.breakout--s {
			width: 60vw;
			max-width: 60vw !important;
			margin: 0 calc(-30vw + 50%);
		}
		.breakout--m {
			width: 70vw;
			max-width: 70vw !important;
			margin: 0 calc(-35vw + 50%);
		}
		.breakout--l {
			width: 80vw;
			max-width: 80vw !important;
			margin: 0 calc(-40vw + 50%);
		}
		.breakout--xl {
			width: 90vw;
			max-width: 90vw !important;
			margin: 0 calc(-45vw + 50%);
		}
	}
	.breakout--full {
		width: 100vw !important;
		max-width: 100vw !important;
		margin-left: calc(-50vw + 50%) !important;
		align-self: flex-start;
	}
	@media ${device.MXlg} {
		[class*="breakout--"]:not(.breakout--full) {
			width: 100% !important;
			max-width: 100% !important;
			margin: 0;
		}
	}
	.stretch {
		display: flex;
		align-content: stretch;
		align-items: stretch;
		justify-content: stretch;
		justify-items: stretch;
	}
	.link--skip {
		position: absolute;
		top: 1rem;
		left: 1rem;
		transform: translateY(-250%);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		background: var(--primary);
		color: var(--primary-ultra-light);
	}
	.link--skip:focus {
		transform: translateY(0);
	}
	.flex--wrap {
		flex-wrap: wrap;
	}
	.text--100 {
		font-weight: 100;
	}
	.text--200 {
		font-weight: 200;
	}
	.text--300 {
		font-weight: 300;
	}
	.text--400 {
		font-weight: 400;
	}
	.text--500 {
		font-weight: 500;
	}
	.text--600 {
		font-weight: 600;
	}
	.text--700 {
		font-weight: 700;
	}
	.text--800 {
		font-weight: 800;
	}
	.text--900 {
		font-weight: 900;
	}
	.text--bold {
		font-weight: bold;
	}
	.text--italic {
		font-style: italic;
	}
	.text--oblique {
		font-style: oblique;
	}
	.text--decoration-none {
		-webkit-text-decoration: none;
		text-decoration: none;
	}
	.text--underline {
		-webkit-text-decoration: underline;
		text-decoration: underline;
	}
	.text--underline-wavy {
		-webkit-text-decoration: underline wavy;
		text-decoration: underline wavy;
	}
	.text--underline-dotted {
		-webkit-text-decoration: underline dotted;
		text-decoration: underline dotted;
	}
	.text--underline-double {
		-webkit-text-decoration: underline double;
		text-decoration: underline double;
	}
	.text--underline-dashed {
		-webkit-text-decoration: underline dashed;
		text-decoration: underline dashed;
	}
	.text--overline {
		-webkit-text-decoration: overline;
		text-decoration: overline;
	}
	.text--line-through {
		-webkit-text-decoration: line-through;
		text-decoration: line-through;
	}
	.text--transform-none {
		text-transform: none;
	}
	.text--uppercase {
		text-transform: uppercase;
	}
	.text--lowercase {
		text-transform: lowercase;
	}
	.text--capitalize {
		text-transform: capitalize;
	}
	.text--left {
		text-align: left;
	}
	.text--center {
		text-align: center;
	}
	.text--right {
		text-align: right;
	}
	.text--justify {
		text-align: justify;
	}
	.aspect--1-1 > iframe {
		position: relative;
	}
	.aspect--1-1 {
		padding-bottom: 0 !important;
	}
	.aspect--1-2 > iframe {
		position: relative;
	}
	.aspect--1-2 {
		padding-bottom: 0 !important;
	}
	.aspect--2-1 > iframe {
		position: relative;
	}
	.aspect--2-1 {
		padding-bottom: 0 !important;
	}
	.aspect--2-3 > iframe {
		position: relative;
	}
	.aspect--2-3 {
		padding-bottom: 0 !important;
	}
	.aspect--3-2 > iframe {
		position: relative;
	}
	.aspect--3-2 {
		padding-bottom: 0 !important;
	}
	.aspect--3-4 > iframe {
		position: relative;
	}
	.aspect--3-4 {
		padding-bottom: 0 !important;
	}
	.aspect--4-3 > iframe {
		position: relative;
	}
	.aspect--4-3 {
		padding-bottom: 0 !important;
	}
	.aspect--16-9 > iframe {
		position: relative;
	}
	.aspect--16-9 {
		padding-bottom: 0 !important;
	}
	.aspect--9-16 > iframe {
		position: relative;
	}
	.aspect--9-16 {
		padding-bottom: 0 !important;
	}
	@media ${device.MXlg} {
		.aspect--xl-1-1 > iframe {
			position: relative;
		}
		.aspect--xl-1-1 {
			padding-bottom: 0 !important;
		}
		.aspect--xl-1-2 > iframe {
			position: relative;
		}
		.aspect--xl-1-2 {
			padding-bottom: 0 !important;
		}
		.aspect--xl-2-1 > iframe {
			position: relative;
		}
		.aspect--xl-2-1 {
			padding-bottom: 0 !important;
		}
		.aspect--xl-2-3 > iframe {
			position: relative;
		}
		.aspect--xl-2-3 {
			padding-bottom: 0 !important;
		}
		.aspect--xl-3-2 > iframe {
			position: relative;
		}
		.aspect--xl-3-2 {
			padding-bottom: 0 !important;
		}
		.aspect--xl-3-4 > iframe {
			position: relative;
		}
		.aspect--xl-3-4 {
			padding-bottom: 0 !important;
		}
		.aspect--xl-4-3 > iframe {
			position: relative;
		}
		.aspect--xl-4-3 {
			padding-bottom: 0 !important;
		}
		.aspect--xl-16-9 > iframe {
			position: relative;
		}
		.aspect--xl-16-9 {
			padding-bottom: 0 !important;
		}
		.aspect--xl-9-16 > iframe {
			position: relative;
		}
		.aspect--xl-9-16 {
			padding-bottom: 0 !important;
		}
	}
	@media ${device.MXmd} {
		.aspect--l-1-1 > iframe {
			position: relative;
		}
		.aspect--l-1-1 {
			padding-bottom: 0 !important;
		}
		.aspect--l-1-2 > iframe {
			position: relative;
		}
		.aspect--l-1-2 {
			padding-bottom: 0 !important;
		}
		.aspect--l-2-1 > iframe {
			position: relative;
		}
		.aspect--l-2-1 {
			padding-bottom: 0 !important;
		}
		.aspect--l-2-3 > iframe {
			position: relative;
		}
		.aspect--l-2-3 {
			padding-bottom: 0 !important;
		}
		.aspect--l-3-2 > iframe {
			position: relative;
		}
		.aspect--l-3-2 {
			padding-bottom: 0 !important;
		}
		.aspect--l-3-4 > iframe {
			position: relative;
		}
		.aspect--l-3-4 {
			padding-bottom: 0 !important;
		}
		.aspect--l-4-3 > iframe {
			position: relative;
		}
		.aspect--l-4-3 {
			padding-bottom: 0 !important;
		}
		.aspect--l-16-9 > iframe {
			position: relative;
		}
		.aspect--l-16-9 {
			padding-bottom: 0 !important;
		}
		.aspect--l-9-16 > iframe {
			position: relative;
		}
		.aspect--l-9-16 {
			padding-bottom: 0 !important;
		}
	}
	@media ${device.MXsm} {
		.aspect--m-1-1 > iframe {
			position: relative;
		}
		.aspect--m-1-1 {
			padding-bottom: 0 !important;
		}
		.aspect--m-1-2 > iframe {
			position: relative;
		}
		.aspect--m-1-2 {
			padding-bottom: 0 !important;
		}
		.aspect--m-2-1 > iframe {
			position: relative;
		}
		.aspect--m-2-1 {
			padding-bottom: 0 !important;
		}
		.aspect--m-2-3 > iframe {
			position: relative;
		}
		.aspect--m-2-3 {
			padding-bottom: 0 !important;
		}
		.aspect--m-3-2 > iframe {
			position: relative;
		}
		.aspect--m-3-2 {
			padding-bottom: 0 !important;
		}
		.aspect--m-3-4 > iframe {
			position: relative;
		}
		.aspect--m-3-4 {
			padding-bottom: 0 !important;
		}
		.aspect--m-4-3 > iframe {
			position: relative;
		}
		.aspect--m-4-3 {
			padding-bottom: 0 !important;
		}
		.aspect--m-16-9 > iframe {
			position: relative;
		}
		.aspect--m-16-9 {
			padding-bottom: 0 !important;
		}
		.aspect--m-9-16 > iframe {
			position: relative;
		}
		.aspect--m-9-16 {
			padding-bottom: 0 !important;
		}
	}
	@media ${device.MXxs} {
		.aspect--s-1-1 > iframe {
			position: relative;
		}
		.aspect--s-1-1 {
			padding-bottom: 0 !important;
		}
		.aspect--s-1-2 > iframe {
			position: relative;
		}
		.aspect--s-1-2 {
			padding-bottom: 0 !important;
		}
		.aspect--s-2-1 > iframe {
			position: relative;
		}
		.aspect--s-2-1 {
			padding-bottom: 0 !important;
		}
		.aspect--s-2-3 > iframe {
			position: relative;
		}
		.aspect--s-2-3 {
			padding-bottom: 0 !important;
		}
		.aspect--s-3-2 > iframe {
			position: relative;
		}
		.aspect--s-3-2 {
			padding-bottom: 0 !important;
		}
		.aspect--s-3-4 > iframe {
			position: relative;
		}
		.aspect--s-3-4 {
			padding-bottom: 0 !important;
		}
		.aspect--s-4-3 > iframe {
			position: relative;
		}
		.aspect--s-4-3 {
			padding-bottom: 0 !important;
		}
		.aspect--s-16-9 > iframe {
			position: relative;
		}
		.aspect--s-16-9 {
			padding-bottom: 0 !important;
		}
		.aspect--s-9-16 > iframe {
			position: relative;
		}
		.aspect--s-9-16 {
			padding-bottom: 0 !important;
		}
	}


	.text--primary {
		color: #fbf10a;
	}
	.text--primary-hover {
		color: #fcf331;
	}
	.text--primary-ultra-light {
		color: #fffee6;
	}
	.text--primary-light {
		color: #fefbb4;
	}
	.text--primary-medium {
		color: #fbf104;
	}
	.text--primary-dark {
		color: #7d7802;
	}
	.text--primary-ultra-dark {
		color: #323001;
	}
	.text--primary-comp {
		color: #0a14fb;
	}
	.text--secondary {
		color: #236a48;
	}
	.text--secondary-hover {
		color: #287a53;
	}
	.text--secondary-ultra-light {
		color: #ecf9f3;
	}
	.text--secondary-light {
		color: #c5ecda;
	}
	.text--secondary-medium {
		color: #3fc082;
	}
	.text--secondary-dark {
		color: #206041;
	}
	.text--secondary-ultra-dark {
		color: #0d261a;
	}
	.text--secondary-comp {
		color: #6a2345;
	}
	.text--accent {
		color: #0a1f15;
	}
	.text--accent-hover {
		color: #0c2418;
	}
	.text--accent-ultra-light {
		color: #ecf9f3;
	}
	.text--accent-light {
		color: #c5ecda;
	}
	.text--accent-medium {
		color: #3ec183;
	}
	.text--accent-dark {
		color: #1f6041;
	}
	.text--accent-ultra-dark {
		color: #0c271a;
	}
	.text--accent-comp {
		color: #1f0a14;
	}
	.text--base {
		color: #272635;
	}
	.text--base-hover {
		color: #2d2c3d;
	}
	.text--base-ultra-light {
		color: #f0f0f4;
	}
	.text--base-light {
		color: #d3d2df;
	}
	.text--base-medium {
		color: #6d6a95;
	}
	.text--base-dark {
		color: #37354a;
	}
	.text--base-ultra-dark {
		color: #16151e;
	}
	.text--base-comp {
		color: #343526;
	}
	.text--white {
		color: #fff;
	}
	.text--black {
		color: #000;
	}
	.text--shade {
		color: black;
	}
	.text--shade-hover {
		color: black;
	}
	.text--shade-ultra-light {
		color: #f2f2f2;
	}
	.text--shade-light {
		color: #d9d9d9;
	}
	.text--shade-medium {
		color: gray;
	}
	.text--shade-dark {
		color: #404040;
	}
	.text--shade-ultra-dark {
		color: #1a1a1a;
	}
	.text--shade-comp {
		color: black;
	}
	.text--xs {
		font-size: 1.26rem;
		font-size: calc(0.9004501688rem + (0.1125562711 * ((100vw - 32rem) / 96)));
		font-size: max(1 * 1rem, 1.0130064399 * 1rem);
	}
	.text--s {
		font-size: 1.42rem;
		font-size: calc(1.200300075rem + (0.1500375094 * ((100vw - 32rem) / 96)));
		font-size: max(1.1 * 1rem, 1.3503375844 * 1rem);
	}
	.text--m {
		font-size: 1.8rem;
		font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
	}
	.text--l {
		font-size: 2.025rem;
		font-size: calc(1.92rem + (0.4794 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.92rem, calc(0.499375vw + 1.7602rem), 2.3994rem);
	}
	.text--xl {
		font-size: 2.28rem;
		font-size: calc(2.304rem + (0.8944002 * ((100vw - 32rem) / 96)));
		font-size: clamp(2.304rem, calc(0.931666875vw + 2.0058666rem), 3.1984002rem);
	}
	.text--xxl {
		font-size: 2.565rem;
		font-size: calc(2.7648rem + (1.4986674666 * ((100vw - 32rem) / 96)));
		font-size: clamp(2.7648rem, calc(1.5611119444vw + 2.2652441778rem), 4.2634674666rem);
	}
	.text--xs.text--larger {
		font-size: 1.43rem;
		font-size: calc(0.9904951857rem + (0.5290144742 * ((100vw - 32rem) / 96)));
		font-size: clamp(0.9904951857rem, calc(0.5510567439vw + 0.8141570276rem), 1.5195096599rem);
	}
	.text--s.text--larger {
		font-size: 1.54rem;
		font-size: calc(1.3203300825rem + (0.7051762941 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.3203300825rem, calc(0.7345586397vw + 1.0852713178rem), 2.0255063766rem);
	}
	.text--m.text--larger {
		font-size: 1.98rem;
		font-size: calc(1.76rem + (0.94 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.76rem, calc(0.9791666667vw + 1.4466666667rem), 2.7rem);
	}
	.text--l.text--larger {
		font-size: 2.2rem;
		font-size: calc(2.112rem + (1.4871 * ((100vw - 32rem) / 96)));
		font-size: clamp(2.112rem, calc(1.5490625vw + 1.6163rem), 3.5991rem);
	}
	.text--xl.text--larger {
		font-size: 2.53rem;
		font-size: calc(2.5344rem + (2.2632003 * ((100vw - 32rem) / 96)));
		font-size: clamp(2.5344rem, calc(2.3575003125vw + 1.7799999rem), 4.7976003rem);
	}
	.text--xxl.text--larger {
		font-size: 2.86rem;
		font-size: calc(3.04128rem + (3.3539211999 * ((100vw - 32rem) / 96)));
		font-size: clamp(3.04128rem, calc(3.4936679166vw + 1.9233062667rem), 6.3952011999rem);
	}
	h1.text--larger {
		font-size: 2.86rem;
		font-size: calc(3.04128rem + (3.3539211999 * ((100vw - 32rem) / 96)));
		font-size: clamp(3.04128rem, calc(3.4936679166vw + 1.9233062667rem), 6.3952011999rem);
	}
	h2.text--larger {
		font-size: 2.53rem;
		font-size: calc(2.5344rem + (2.2632003 * ((100vw - 32rem) / 96)));
		font-size: clamp(2.5344rem, calc(2.3575003125vw + 1.7799999rem), 4.7976003rem);
	}
	h3.text--larger {
		font-size: 2.2rem;
		font-size: calc(2.112rem + (1.4871 * ((100vw - 32rem) / 96)));
		font-size: clamp(2.112rem, calc(1.5490625vw + 1.6163rem), 3.5991rem);
	}
	h4.text--larger {
		font-size: 1.98rem;
		font-size: calc(1.76rem + (0.94 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.76rem, calc(0.9791666667vw + 1.4466666667rem), 2.7rem);
	}
	h5.text--larger {
		font-size: 1.54rem;
		font-size: calc(1.3203300825rem + (0.7051762941 * ((100vw - 32rem) / 96)));
		font-size: clamp(1.3203300825rem, calc(0.7345586397vw + 1.0852713178rem), 2.0255063766rem);
	}
	h6.text--larger {
		font-size: 1.43rem;
		font-size: calc(0.9904951857rem + (0.5290144742 * ((100vw - 32rem) / 96)));
		font-size: clamp(0.9904951857rem, calc(0.5510567439vw + 0.8141570276rem), 1.5195096599rem);
	}
	.text--100 {
		font-weight: 100;
	}
	.text--200 {
		font-weight: 200;
	}
	.text--300 {
		font-weight: 300;
	}
	.text--400 {
		font-weight: 400;
	}
	.text--500 {
		font-weight: 500;
	}
	.text--600 {
		font-weight: 600;
	}
	.text--700 {
		font-weight: 700;
	}
	.text--800 {
		font-weight: 800;
	}
	.text--900 {
		font-weight: 900;
	}
	.text--bold {
		font-weight: bold;
	}
	.text--italic {
		font-style: italic;
	}
	.text--oblique {
		font-style: oblique;
	}
	.text--decoration-none {
		-webkit-text-decoration: none;
		text-decoration: none;
	}
	.text--underline {
		-webkit-text-decoration: underline;
		text-decoration: underline;
	}
	.text--underline-wavy {
		-webkit-text-decoration: underline wavy;
		text-decoration: underline wavy;
	}
	.text--underline-dotted {
		-webkit-text-decoration: underline dotted;
		text-decoration: underline dotted;
	}
	.text--underline-double {
		-webkit-text-decoration: underline double;
		text-decoration: underline double;
	}
	.text--underline-dashed {
		-webkit-text-decoration: underline dashed;
		text-decoration: underline dashed;
	}
	.text--overline {
		-webkit-text-decoration: overline;
		text-decoration: overline;
	}
	.text--line-through {
		-webkit-text-decoration: line-through;
		text-decoration: line-through;
	}
	.text--transform-none {
		text-transform: none;
	}
	.text--uppercase {
		text-transform: uppercase;
	}
	.text--lowercase {
		text-transform: lowercase;
	}
	.text--capitalize {
		text-transform: capitalize;
	}
	.text--left {
		text-align: left;
	}
	.text--center {
		text-align: center;
	}
	.text--right {
		text-align: right;
	}
	.text--justify {
		text-align: justify;
	}
	.link--primary a, a.link--primary {
		color: #fbf10a;
	}
	.link--primary-hover a, a.link--primary-hover {
		color: #fcf331;
	}
	.link--primary-ultra-light a, a.link--primary-ultra-light {
		color: #fffee6;
	}
	.link--primary-light a, a.link--primary-light {
		color: #fefbb4;
	}
	.link--primary-medium a, a.link--primary-medium {
		color: #fbf104;
	}
	.link--primary-dark a, a.link--primary-dark {
		color: #7d7802;
	}
	.link--primary-ultra-dark a, a.link--primary-ultra-dark {
		color: #323001;
	}
	.link--primary-comp a, a.link--primary-comp {
		color: #0a14fb;
	}
	.link--secondary a, a.link--secondary {
		color: #236a48;
	}
	.link--secondary-hover a, a.link--secondary-hover {
		color: #287a53;
	}
	.link--secondary-ultra-light a, a.link--secondary-ultra-light {
		color: #ecf9f3;
	}
	.link--secondary-light a, a.link--secondary-light {
		color: #c5ecda;
	}
	.link--secondary-medium a, a.link--secondary-medium {
		color: #3fc082;
	}
	.link--secondary-dark a, a.link--secondary-dark {
		color: #206041;
	}
	.link--secondary-ultra-dark a, a.link--secondary-ultra-dark {
		color: #0d261a;
	}
	.link--secondary-comp a, a.link--secondary-comp {
		color: #6a2345;
	}
	.link--accent a, a.link--accent {
		color: #0a1f15;
	}
	.link--accent-hover a, a.link--accent-hover {
		color: #0c2418;
	}
	.link--accent-ultra-light a, a.link--accent-ultra-light {
		color: #ecf9f3;
	}
	.link--accent-light a, a.link--accent-light {
		color: #c5ecda;
	}
	.link--accent-medium a, a.link--accent-medium {
		color: #3ec183;
	}
	.link--accent-dark a, a.link--accent-dark {
		color: #1f6041;
	}
	.link--accent-ultra-dark a, a.link--accent-ultra-dark {
		color: #0c271a;
	}
	.link--accent-comp a, a.link--accent-comp {
		color: #1f0a14;
	}
	.link--base a, a.link--base {
		color: #272635;
	}
	.link--base-hover a, a.link--base-hover {
		color: #2d2c3d;
	}
	.link--base-ultra-light a, a.link--base-ultra-light {
		color: #f0f0f4;
	}
	.link--base-light a, a.link--base-light {
		color: #d3d2df;
	}
	.link--base-medium a, a.link--base-medium {
		color: #6d6a95;
	}
	.link--base-dark a, a.link--base-dark {
		color: #37354a;
	}
	.link--base-ultra-dark a, a.link--base-ultra-dark {
		color: #16151e;
	}
	.link--base-comp a, a.link--base-comp {
		color: #343526;
	}
	.link--white a, a.link--white {
		color: #fff;
	}
	.link--black a, a.link--black {
		color: #000;
	}
	.link--shade a, a.link--shade {
		color: black;
	}
	.link--shade-hover a, a.link--shade-hover {
		color: black;
	}
	.link--shade-ultra-light a, a.link--shade-ultra-light {
		color: #f2f2f2;
	}
	.link--shade-light a, a.link--shade-light {
		color: #d9d9d9;
	}
	.link--shade-medium a, a.link--shade-medium {
		color: gray;
	}
	.link--shade-dark a, a.link--shade-dark {
		color: #404040;
	}
	.link--shade-ultra-dark a, a.link--shade-ultra-dark {
		color: #1a1a1a;
	}
	.link--shade-comp a, a.link--shade-comp {
		color: black;
	}
	.link--skip {
		position: absolute;
		top: 1rem;
		left: 1rem;
		transform: translateY(-250%);
		padding: 0.8rem 1.6em;
		border-width: 0.1rem;
		border-radius: 0;
		background: var(--primary);
		color: var(--primary-ultra-light);
	}
	.link--skip:focus {
		transform: translateY(0);
	}

	@supports (font-size: calc(1vw + 2vw)) {
		:root {
			--text-xs: calc(0.9004501688rem + (0.1125562711 * ((100vw - 32rem) / 96)));
			--text-s: calc(1.200300075rem + (0.1500375094 * ((100vw - 32rem) / 96)));
			--text-m: calc(1.6rem + (0.2 * ((100vw - 32rem) / 96)));
			--text-l: calc(1.92rem + (0.4794 * ((100vw - 32rem) / 96)));
			--text-xl: calc(2.304rem + (0.8944002 * ((100vw - 32rem) / 96)));
			--text-xxl: calc(2.7648rem + (1.4986674666 * ((100vw - 32rem) / 96)));
		}
		@media ${device.lg} {
			:root {
				--text-xs: 1.0130064399rem;
				--text-s: 1.3503375844rem;
				--text-m: 1.8rem;
				--text-l: 2.3994rem;
				--text-xl: 3.1984002rem;
				--text-xxl: 4.2634674666rem;
			}
		}
	}
	@supports (font-size: clamp(1vw, 2vw, 3vw)) {
		:root {
			--text-xs: max(1 * 1rem, 1.0130064399 * 1rem);
			--text-s: max(1.1 * 1rem, 1.3503375844 * 1rem);
			--text-m: clamp(1.6rem, calc(0.2083333333vw + 1.5333333333rem), 1.8rem);
			--text-l: clamp(1.92rem, calc(0.499375vw + 1.7602rem), 2.3994rem);
			--text-xl: clamp(2.304rem, calc(0.931666875vw + 2.0058666rem), 3.1984002rem);
			--text-xxl: clamp(2.7648rem, calc(1.5611119444vw + 2.2652441778rem), 4.2634674666rem);
		}
	}
	@supports (padding: calc(1vw + 2vw)) {
		:root {
			--space-xs: calc(1.0666666667rem + (0.2666666667 * ((100vw - 32rem) / 96)));
			--space-s: calc(1.6rem + (0.4 * ((100vw - 32rem) / 96)));
			--space-m: calc(2.4rem + (0.6 * ((100vw - 32rem) / 96)));
			--space-l: calc(3.1992rem + (1.3008 * ((100vw - 32rem) / 96)));
			--space-xl: calc(4.2645336rem + (2.4854664 * ((100vw - 32rem) / 96)));
			--space-xxl: calc(5.6846232888rem + (4.4403767112 * ((100vw - 32rem) / 96)));
			--section-space-xs: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 96)));
			--section-space-s: calc(4.8rem + (3.2 * ((100vw - 32rem) / 96)));
			--section-space-m: calc(7.2rem + (4.8 * ((100vw - 32rem) / 96)));
			--section-space-l: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 96)));
			--section-space-xl: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 96)));
			--section-space-xxl: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 96)));
		}
		@media ${device.lg} {
			:root {
				--space-xs: 1.3333333333rem;
				--space-s: 2rem;
				--space-m: 3rem;
				--space-l: 4.5rem;
				--space-xl: 6.75rem;
				--space-xxl: 10.125rem;
				--section-space-xs: 5.3333333333rem;
				--section-space-s: 8rem;
				--section-space-m: 12rem;
				--section-space-l: 18rem;
				--section-space-xl: 27rem;
				--section-space-xxl: 40.5rem;
			}
		}
	}
	@supports (padding: clamp(1vw, 2vw, 3vw)) {
		:root {
			--space-xs: clamp(1.0666666667rem, calc(0.2777777778vw + 0.9777777778rem), 1.3333333333rem);
			--space-s: clamp(1.6rem, calc(0.4166666667vw + 1.4666666667rem), 2rem);
			--space-m: clamp(2.4rem, calc(0.625vw + 2.2rem), 2.4rem);
			--space-l: clamp(3.1992rem, calc(1.355vw + 2.7656rem), 4.5rem);
			--space-xl: clamp(4.2645336rem, calc(2.5890275vw + 3.4360448rem), 6.75rem);
			--space-xxl: clamp(5.6846232888rem, calc(4.6253924075vw + 4.2044977184rem), 10.125rem);
			--section-space-xs: clamp(3.2rem, calc(2.2222222222vw + 2.4888888889rem), 5.3333333333rem);
			--section-space-s: clamp(4.8rem, calc(3.3333333333vw + 3.7333333333rem), 8rem);
			--section-space-m: clamp(7.2rem, calc(5vw + 5.6rem), 12rem);
			--section-space-l: clamp(9.5976rem, calc(8.7525vw + 6.7968rem), 18rem);
			--section-space-xl: clamp(12.7936008rem, calc(14.7983325vw + 8.0581344rem), 27rem);
			--section-space-xxl: clamp(17.0538698664rem, calc(24.4230522225vw + 9.2384931552rem), 40.5rem);
		}
	}
	.d-block {
		display: block;
	}
	.card {
		font-size: var(--text-m);
		background: #202020;
		color: #fff;
		border-radius: 1.6rem;
		box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02), 0px 6.7px 5.3px rgba(0, 0, 0, 0.028), 0px 12.5px 10px rgba(0, 0, 0, 0.035), 0px 22.3px 17.9px rgba(0, 0, 0, 0.042), 0px 41.8px 33.4px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);
		padding: var(--space-s);

		&.primary {
			background-color: ${ThemeDefault.primary};
			color: ${ThemeDefault.white};
		}
		&.centered {
			align-items: center;
			justify-items: center;
			align-content: center;
			justify-content: center;
			text-align: center;

			& div {
				margin: 0 auto;
			}
		}
		&.larger-content {
			font-size: 150%;
		}
	}
	.swiper {
		overflow:visible !important;
	}
	.swiper-scrollbar {
		display: none;
	}
	.swiper-button-next, 
	.swiper-button-prev {
		color: ${ThemeDefault.white};
		top: 100%;
		right: 0 !important;
		left: auto !important;
		transform: translateY(100%);
		width: 32px !important;
	}
	.swiper-button-prev {
		right: 32px !important;
	}
	.swiper-button-next::after, .swiper-button-prev::after {
		font-size: 24px !important;
	}
	.card-list li {
		margin-bottom: 0.8rem;
	}
	.pointer {
		cursor: pointer;
	}
	.projcet-title {
		margin-bottom: 0;
	}
`

export default GlobalStyle
