
const ThemeDefault = {

  /* FONT SIZES */
  fontPrimary: "Mont, open-sans, sans-serif",

  trueBlack: '#000',
  black: '#1A1A1A',
  blackDarker: '#070707',
  trueWhite: '#fff',
  white: 'hsl(227deg 40% 85%)',
  primary: 'hsl(346, 80.1%, 47.3%)', //'hsl(347deg 85% 58%)',
  secondary: 'hsl(226.57342657343deg, 62.445414847162%, 44.901960784314%)',
  tertiary: 'hsl(17.202797202797deg, 62.445414847162%, 44.901960784314%)',

}

export default ThemeDefault