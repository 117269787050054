import React, { useEffect } from 'react'
import * as THREE from 'three';
import Noise from 'noise-library';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components"

gsap.registerPlugin(ScrollTrigger);


const CanvasEl = styled.canvas`
  position: fixed;
  top:0; 
  left: 0;
  width: 100%; 
  height: 100%;
  z-index: -1;
`

export default function Canvas() {

  useEffect(() => {

    let renderer;
    let camera;

    renderer = new THREE.WebGLRenderer({ alpha: true, canvas: document.getElementById('hero-canvas'), antialias: true});
    // default bg canvas color //
    renderer.setClearColor(0x1A1A1A, 0);
    //  use device aspect ratio //
    renderer.setPixelRatio(window.devicePixelRatio);
    // set size of canvas within window //
    renderer.setSize(window.innerWidth, window.innerHeight);
    
    var scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera( 18,  window.innerWidth/window.innerHeight, 0.1, 1000 );
    camera.position.z = 5;
    camera.position.x = -1;
    
    let sphere_geometry = new THREE.SphereGeometry(1, 128, 128);
    let material = new THREE.MeshNormalMaterial();
    
    let sphere = new THREE.Mesh(sphere_geometry, material);
    scene.add(sphere);
    
    var update = function() {

      // change '0.003' for more aggressive animation
      var time = performance.now() * 0.00035;
      //console.log(time)
      
      //go through vertices here and reposition them
      
      // change 'k' value for more spikes
      var k = 0.4;
      for (let i = 0; i < sphere.geometry.vertices.length; i++) {
        var p = sphere.geometry.vertices[i];
        p.normalize().multiplyScalar(1 + 0.4 * Noise.perlin3(p.x * k + time, p.y * k, p.z * k));
      }
      sphere.geometry.computeVertexNormals();
      sphere.geometry.normalsNeedUpdate = true;
      sphere.geometry.verticesNeedUpdate = true;

    }
    
    window.addEventListener('resize', () => {

        // Update camera
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
    
        // Update renderer
        renderer.setSize(window.innerWidth, window.innerHeight);
        
    });
    
    function animate() {
      //sphere.rotation.x += 0.01;
      //sphere.rotation.y += 0.01;
    
      update();
      /* render scene and camera */
      renderer.render(scene,camera);
      requestAnimationFrame(animate);
    }
    
    //Three JS Move Camera on Scroll Down
    gsap.to(sphere.position, {
      scrollTrigger: {
      trigger: "#root",
      start: "top top",
      end: `+=${window.innerHeight}`,
      // markers: true,
      scrub: true,
      // toggleActions: "restart pause resume pause"
    },
        y: 1.8
      });
      

      requestAnimationFrame(animate);

  }, []);

  return (
    <CanvasEl id="hero-canvas" />
  )
}
