// import { useCallback, useLayoutEffect, useState } from "react"
import styled from "styled-components"
import device from "../theme/MediaQueries"
import ThemeDefault from "../theme/ThemeDefault"

const ImageWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;

  .image-inner {
    position: absolute;
    width: auto;
    height: auto;
    width: 50%;
    height: 50%;
    opacity: 0;

    & .inner-inner {
      position: absolute;
      width: auto;
      height: auto;
      width: 400px;
      padding-bottom: 400px;
      overflow: hidden;
      border: 1px solid ${ThemeDefault.white};
      border-radius: 50%;
      opacity: 0;
      background: ${ThemeDefault.white};
      transform: translate(-50%, -50%);
    }
      
    &.is-active {
      opacity: 1;

      @media ${device.MXmd} {
        opacity: 0;
      }
      & .inner-inner {
        opacity: 1;
        @media ${device.MXmd} {
          opacity: 0;
        }
      }
    }
  }
  
  & img {
    opacity: 0;
    position: absolute;
    width: auto;
    height: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;

    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;


    &.is-active {
      opacity: 1;
      /* mix-blend-mode: multiply; */

      @media ${device.MXmd} {
        display: none !important;
        opacity: 0;
      }
    }
  }
`

// const getDimensionObject = (node) => {
//   const rect = node.getBoundingClientRect();

//   return {
//     width: rect.width,
//     height: rect.height
//   }
// }

// const useSize = () => {
//   const [dimensions, setDimensions] = useState({})
//   const [node, setNode] = useState(null)

//   const ref = useCallback(node => {
//     setNode(node);
//   }, []);

//   useLayoutEffect(()=>{
//       if (node) {
//         const measure = ()=> setDimensions(getDimensionObject(node))
//         measure();
//       }
//   }, [node])

//   return [ref, dimensions];

// }

const Media = ({ url, active, alt, x, y }) => {

  return (
    <ImageWrap className="image-wrap">
      <div className="is-active image-inner"  style={{ transform: `translate(${x}px, ${y}px)`}}>
        <div className="inner-inner">
          <img 
            src={url} 
            className="is-active"
            alt={alt} 
            />
        </div>
      </div>
    </ImageWrap>
  )
}

export default Media