import styled from "styled-components"
import device from "../theme/MediaQueries"
import RevealText from "./RevealText"
import SectionTitle from "./SectionTitle"


const SkillSetDiv = styled.div`
  padding-bottom: 25vh;
  max-width: 1280px;
  margin: 0 auto;

  & .introText {
    font-size: clamp(1.3rem, 1.5vw, 2rem);

    @media ${device.MXmd} {
      font-size: clamp(1.8rem,5.32vw,2.8rem)
    }
  }
  
  & .split-parent {
    margin-bottom: 0.8rem;
  }

  @media ${device.MXmd} {
    & .wrapper {
      margin-bottom: 1.6rem;
    }
  }

`


export default function SkillSet() {

  return (
    <SkillSetDiv className="grid--4-1 grid--m-1 center">
      <div className="content-wrap">
        <SectionTitle>
          <h2><RevealText>Capability</RevealText></h2>
        </SectionTitle>

        <div className="grid--4 grid--m-2 grid--s-2">
          <div className="wrapper">
            <RevealText className="introText" delayM="0" delayS="0" delay="0.6" windowPosition="90%">
              <div>JavaScript</div>
              <div>React</div>
              <div>Angular.js</div>
              <div>Gatsby</div>
              <div>GraphQL</div>
              <div>jQuery</div>
              <div>RESTful APIs</div>
              <div>GIT</div>
            </RevealText>    
          </div>
          <div className="wrapper">
            <RevealText className="introText" delayM="0.15" delayS="0" delay="0.75" windowPosition="90%">
              <div>PHP</div>
              <div>Wordpress</div>
              <div>MySQL</div>
              <div>Firebase</div>
              <div>Agile Dev</div>
            </RevealText>    
          </div>
          <div className="wrapper">
            <RevealText className="introText" delayM="0" delayS="0" delay="0.9" windowPosition="90%">
              <div>HTML5</div>
              <div>CSS3</div>
              <div>SASS</div>
              <div>LESS</div>
            </RevealText>    
          </div>
          <div className="wrapper">
            <RevealText className="introText" delayM="0.15" delayS="0" delay="0.9" windowPosition="90%">
              <div>Adobe CC</div>
              <div>Sketch</div>
              <div>Figma</div>
            </RevealText>    
          </div>
        </div>
        
      </div>
    
    </SkillSetDiv>
  )
}
