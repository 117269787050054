
import styled from "styled-components"
import ThemeDefault from "../theme/ThemeDefault"
import Arrow from "../images/arrow.svg"
import device from "../theme/MediaQueries"
// import { useEffect, useState } from "react"
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ProjectDiv = styled.div`
  cursor: pointer;
  padding: var(--space-xxl) 0;
  z-index: 9999;
  position: relative;
  display: grid !important;
  /* border-top: 1px solid ${ThemeDefault.white}; */

  border-bottom: 1px solid rgba(255, 255, 255, 0.4);

  & h3 {
    transition: opacity 0.35s cubic-bezier(.77, 0, 0.175, 1);
    font-size: clamp(2.7rem, 2.8vw, 4.5rem);
    font-weight: 300;
    color: ${ThemeDefault.white} !important;
    text-decoration: none;
    padding: 0;
    line-height: 1;
    margin: 0;
    margin-bottom: 1.6rem;
  }

  & a {
    color: ${ThemeDefault.white} !important;
    text-decoration: none;
  }
  
  &:hover {
    z-index: 9999;
    position: relative;

    h3 {
      opacity: 1;
    }
    .arrow {
      transform: translateX(15%);
    }
  }
  
  & .rowInner {
    align-content: center;
    display: flex;
    &:nth-child(1) {
      display: flex;
      flex-direction: column;
    }
    &.arrowCol {
      justify-content: end;

      @media ${device.MXmd} {

        display: flex;
        margin-top: 1.6rem;
      }
    }

    & .flex--col {
      & .image-wrap {
        display: none;

        @media ${device.MXmd} {
          display: block;
          width: 100%;
          margin-bottom: 2rem;
          overflow: hidden;
          border-radius: 0.8rem;
          box-shadow:
            0px 3.3px 3.5px rgba(0, 0, 0, 0.02),
            0px 8px 8.4px rgba(0, 0, 0, 0.037),
            0px 15px 15.8px rgba(0, 0, 0, 0.05),
            0px 26.8px 28.1px rgba(0, 0, 0, 0.059),
            0px 50.1px 52.6px rgba(0, 0, 0, 0.062),
            0px 120px 126px rgba(0, 0, 0, 0.07);

          & img {
            width: 100%
          }
        }
      }
    }
  }
  & a {
    color:#fff;
    text-decoration: none;
    font-size: clamp(1.4rem, 2vw, 3rem);

  }
  & .arrow {
    transition: 300ms; 
    width: 25%;
    max-width: 150px;
  }
  & .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    margin-bottom: 0.8rem;

    @media ${device.MXmd} {
      flex-direction: column;
      margin-bottom: 0.8rem;
    }
    & h3 {
      display: inline-flex;
      margin-right: 8px;

      @media ${device.MXmd} {
        line-height: 1.3;
      }
    }
    & .stack {
      font-size: 100%;
    }
  }
`

const Project = ({project, index, setActiveIndex}) => {
  // console.log(project)

  const el = useRef()

  useEffect(() => {


    gsap.from(el.current, {
      scrollTrigger:  {
        trigger: el.current,
        start: "top 65%",
        end: "bottom 35%",
        // markers: true,
        pin: false,
      },
      opacity: 0,
      duration: 2,
      yPercent: 15,
      ease: "power4",
    });


  },[])


  return (
    <ProjectDiv className="grid--3-1 grid--m-1 grid--s-1 grid--xs-1" ref={el}
      onMouseEnter={() => setActiveIndex(index)}
      onMouseLeave={() => setActiveIndex(-1)}
    >
      <div className="rowInner">
        <div className="flex--col">
          <div className="image-wrap"><img src={project.bannerImage} alt={project.title} /></div>
          <div className="row"><h3>{project.title}</h3></div>
          <div className="row"><div className="stack">{project.stack.join(' / ')}</div></div>
          <div className="row"><div className="cats">{project.cats.join(', ')}</div></div>
          {project.descripton && <div className="descripton">{project.descripton}</div>}
        </div>
      </div>
      <div className="rowInner arrowCol">
        <img src={Arrow} className="arrow" alt="Arrow"  />
      </div>
    </ProjectDiv>

)}

export default Project