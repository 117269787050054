import styled from "styled-components"

const SectionTitleDiv = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  & h2 {
    font-size: var(--font-s);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.6
  }
`

export default function SectionTitle({children}) {
  return (
    <SectionTitleDiv>
      {children}
    </SectionTitleDiv>
  )
}
